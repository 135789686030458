import { inject as service } from '@ember/service';
import ApplicationSerializer from './application';
import { v4 } from 'uuid';

/**
 * Serializer for handling user two-factor authentication configurations.
 */
export default class UserTwoFaConfigurationSerializer extends ApplicationSerializer {
  @service currentUser;

  /**
   * Override the key for attributes to return the attribute as is. Necessary given
   * `ApplicationSerializer`'s default behavior.
   * @param {string} attr - The attribute name.
   * @returns {string} The attribute name.
   */
  keyForAttribute(attr) {
    return attr;
  }

  /**
   * Serialize the record into the format expected by the API.
   * @returns {Object} The serialized record.
   */
  serialize() {
    let json = super.serialize(...arguments);
    const payload = {
      'delivery_type': json.data.attributes.deliveryType,
    };

    if (json.data.attributes.phoneNumber) {
      payload['cell_phone'] = json.data.attributes.phoneNumber
    }

    return payload
  }

  /**
   * Normalize the response from the API to match the JSON:API format.
   * @param {DS.Store} store - The store instance.
   * @param {DS.Model} primaryModelClass - The primary model class.
   * @param {Object} payload - The payload from the API.
   * @param {string|number} id - The ID of the record.
   * @param {string} requestType - The request type.
   * @returns {Object} The normalized response.
   */
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    payload.data = {
      id: this.currentUser?.user?.userId || payload.delivery_type || v4(),
      type: 'user-two-fa-configuration',
      attributes: {
        deliveryType: payload.deliveryType || null,
        phoneNumber: payload.phoneNumber || null,
      },
    };

    delete payload.deliveryType;
    delete payload.phoneNumber;

    return super.normalizeResponse(...arguments);
  }
}
