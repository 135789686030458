/** @format */

import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, sort } from '@ember/object/computed';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Model.extend({
	currentUser: service(),
	validations: computed(
		'attorneys.[]',
		'caseType.content',
		'caseStatus.content',
		'date',
		function () {
			const validations = {
				isValid: true,
				attrs: {
					attorneys: {
						isValid: Boolean(this.get('attorneys').length >= 1),
						message: 'Must assign a minimum of 144',
					},
					caseType: {
						isValid: Boolean(this.get('caseType.content')),
						message: 'Practice Area is a required field.',
					},
					caseStatus: {
						isValid: Boolean(this.get('caseStatus.content')),
						message: 'Status is a required field.',
					},
					date: {
						//* Only check if date is a valid date if one is set, date is not to be required though.
						isValid: this.get('date')
							? moment(this.get('date')).isValid()
							: true,
						message: 'A valid date is required.',
					},
				},
			};

			for (let prop in validations.attrs) {
				if (validations.attrs[prop].isValid === false) {
					validations.isValid = false;
				}
			}

			return validations;
		},
	),

	init() {
		this._super(...arguments);
		// Notifications aren't setup as relationships anymore so we need to manually set it on the case
		const notifications = this.store.peekAll('notification') || [];
		const theCaseNotifications = notifications.filter((n) => {
			return n.get('caseId') === this.id;
		});

		const chatNotifications = this.store.peekAll('chat-notification') || [];
		const theCaseChatNotifications = chatNotifications.filter((cn) => {
			return cn.get('readableId') === this.id;
		});

		this.set('notifications', theCaseNotifications);
		this.set('chatNotifications', theCaseChatNotifications);
	},

	integrationId: attr('string'),

	newMessages: attr('number'),
	//* INstead of touching too many places in the app creating more potentials for bugs,
	//* We will just use the same properties but as aliases for the renamed and new properties from API
	newChatMessages: alias('newExternalChatMessages'),
	newExternalChatMessages: attr('number'),
	newInternalMessages: alias('newInternalChatMessages'),
	newInternalChatMessages: attr('number'),
	unsentScheduledMessagesCount: attr('number'),
	mostRecentMessageContent: attr('string'),
	mostRecentMessageUrgency: attr('number'),
	mostRecentMessageCategory: attr('string'),
	mostRecentMessageSentiment: attr('number'),

	unreadMessageCount: computed(
		'newMessages',
		'newChatMessages',
		'newInternalMessages',
		function () {
			const newMessages = this.newMessages || 0;
			const newChatMessages = this.newChatMessages || 0;
			const newInternalMessages = this.newInternalMessages || 0;

			return newMessages + newChatMessages + newInternalMessages;
		},
	),

	users: computed('attorneys', 'paralegals', 'members', 'clients', function () {
		const attorneys = this.attorneys?.toArray() || [];
		const paralegals = this.paralegals?.toArray() || [];
		const clients = this.clients?.toArray() || [];
		const members = this.members?.toArray() || [];

		return new Set([...attorneys, ...paralegals, ...clients, ...members]);
	}),
	closed: attr('boolean'),
	activatedDate: attr('date'),
	activationFailedReason: attr('string'),
	description: attr('string'),
	date: attr('string'),
	readableId: attr('string'),
	onHold: attr('boolean'),
	onHoldExplanation: attr('string'),
	allowClientMessaging: attr('boolean'),
	overrideMessagingLimit: attr('boolean'),
	sendAsPrimary: attr('boolean'),
	updatedAt: attr('date'),
	deletedAt: attr('date'),
	wantsSms: attr('boolean'),
	translationEnabled: attr('boolean'),
	group: attr('string'),
	referralSource: attr('string'),
	referredAt: attr('date'),
	notes: attr('string'),
	mostRecentMessageSentAt: attr('date'),
	mostRecentNps: attr('string'),
	caseSettings: attr(),
	targetLanguage: attr('string'),

	automations: hasMany('automation'),
	actions: alias('automations'),
	caseStatus: belongsTo('case-status'),
	caseType: belongsTo('case-type'),
	clients: hasMany('client'),
	lastName: alias('clients'), //*needed for sorting on cases table
	firm: belongsTo('firm'),
	netPromoterScores: hasMany('net-promoter-score'),
	messages: hasMany('message'),
	scheduledMessages: hasMany('message'),
	paralegals: hasMany('paralegal'),
	attorneys: hasMany('attorney'),
	attorney: belongsTo('attorney'),
	primaryAttorney: belongsTo('attorney'),
	chats: hasMany('chat'),
	treatment: hasMany('treatment'),
	appointment: hasMany('appointment'),
	medicalProviders: hasMany('medical-provider'),
	checklistItemsSortProp: ['position'], //eslint-disable-line ember/avoid-leaking-state-in-ember-objects
	checklistItems: hasMany('checklistItem'),
	sortedChecklistItems: sort('checklistItems', 'checklistItemsSortProp'),

	allowMessaging: computed(
		'currentUser.userType',
		'caseSettings.{disable_inbound_messaging,disable_inbound_messaging_case_closed}',
		'closed',
		function () {
			if (this.currentUser.userType != 'client') return true;
			if (this.caseSettings?.disable_inbound_messaging) return false;
			if (
				this.caseSettings?.disable_inbound_messaging_case_closed &&
				this.closed
			)
				return false;

			return true;
		},
	),

	localSave() {
		const adapter = this.store.adapterFor('case');
		adapter.set('preventNetworkRequest', true);
		return this.save();
	},
});
