import Model, { attr } from '@ember-data/model';

/**
 * Model representing the user two-factor authentication configuration.
 */
export default class UserTwoFaConfigurationsModel extends Model {
  /**
   * The delivery type for the two-factor authentication.
   * @type {string}
   */
  @attr('string') deliveryType;

  /**
   * The phone number for the two-factor authentication.
   * @type {string}
   */
  @attr('string') phoneNumber;
}
