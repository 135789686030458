import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Errors from 'case-status/constants/errors';


export default class BulkActionRoute extends Route {
	@service router;
	@service session;
	@service store;
	errors = [];

	beforeModel(transition) {
		if (!this.session.isAuthenticated) {
			let loginController = this.controllerFor('login');
			loginController.set('previousTransition', transition);
			this.router.transitionTo('login');
		} else {
			super.beforeModel(...arguments);
		}
	}

	async model({ bulk_action_id }) {
		super.model(...arguments);
		try {
			const bulkAction = await this.store.findRecord('bulk-action', bulk_action_id);
			if (bulkAction) {
				return bulkAction;
			} else {
				this.errors = Errors.mapResponseErrors('Bulk Action was not found');
			}
		} catch (err) {
			this.errors = Errors.mapResponseErrors(err);
		}
	}

	setupController(controller, model) {
		super.setupController(controller, model);
		const content = model?.get('messageContent');
		const validated = !!(model?.get('validatedAt') || model?.get('completedAt'))
		const caseCount = model?.get('caseCount') || 0;
		const attachmentName = model?.get('attachmentName');
		controller.setProperties({
			content,
			validated,
			caseCount,
			attachmentName,
			errors: this.errors,
		});
	}
}