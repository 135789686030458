import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

@classic
export default class AppTwoFactorAuthConfigureController extends Controller {
	@service store;
	@service currentUser;
	@tracked errorMessage = [];
	@tracked userTwoFactorConfiguration;
	@tracked loading = true;

	async init() {
		super.init(...arguments);
		this.submit = this.submit.bind(this);
		this.getErrorMessage = this.getErrorMessage.bind(this);

		await this.currentUser.load();

		this.userTwoFactorConfiguration = this.store.createRecord(
			'user-two-fa-configuration',
			{
				deliveryType: 'mobile_phone',
				phoneNumber: '',
			},
		);

		if (this.currentUser.user.cellPhone) {
			await this.submit();
		}

		this.loading = false;
	}

  /**
   * Submit the two factor configuration
   * @param {Event} evt
   * @returns {Promise<void>}
   */
	async submit(evt) {
		if (evt) evt.preventDefault();
		try {
			await this.userTwoFactorConfiguration.save();
			if (this.currentUser.user.cellPhone) {
				this.set(
					'currentUser.user.cellPhone',
					this.userTwoFactorConfiguration.phoneNumber,
				);
			}
			this.transitionToRoute('two-factor-auth.verify');
		} catch (error) {

			this.errorMessage = [this.getErrorMessage(error)];
			return;
		}
	}

	/**
	 * Parse the error message from the server response
	 *
	 * @param {*} error
	 * @returns the error message
	 */
	getErrorMessage(error) {
		let errorMessage = 'An error occurred';
		if (error.errors && error.errors.length > 0) {
			const errorDetail = error.errors[0].detail;

			try {
				const parsedError = JSON.parse(errorDetail.replace(/'/g, '"'));
				errorMessage = Object.keys(parsedError)
					? Object.keys(parsedError).map((key) => {
							return parsedError[key];
          })[0]
					: errorMessage;
			} catch {
				errorMessage = errorDetail;
			}
		}
		return errorMessage;
	}
}
