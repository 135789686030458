import TwoFactorSerializer from './two-factor';

export default class TwoFactorVerifySerializer extends TwoFactorSerializer {

  /**
   * Serialize the record into the format expected by the API.
   * @returns {Object} The serialized record.
   */
  serialize() {
    let json = super.serialize(...arguments);
    return {
      ...json.data.attributes
    };
  }  

	/**
	 * Normalize the response from the API to match the JSON:API format.
	 * @param {DS.Store} store - The store instance.
	 * @param {DS.Model} primaryModelClass - The primary model class.
	 * @param {Object} payload - The payload from the API.
	 * @param {string|number} id - The ID of the record.
	 * @param {string} requestType - The request type.
	 * @returns {Object} The normalized response.
	 */
	normalizeResponse(store, primaryModelClass, payload, id, requestType) {
		payload.data = {
			id: this.currentUser.user.userId,
			type: 'two-factor-verify',
			attributes: {},
		};

		delete payload.message;

		return super.normalizeResponse(...arguments);
	}
}
