import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FilterDropdown2\n\t@title={{this.args.title}}\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='index'\n\t@onChange={{action 'handleOnChange'}}\n\t@hideFilterEnd={{true}}\n\t@modalType='Category'\n/>", {"contents":"<FilterDropdown2\n\t@title={{this.args.title}}\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='index'\n\t@onChange={{action 'handleOnChange'}}\n\t@hideFilterEnd={{true}}\n\t@modalType='Category'\n/>","moduleName":"case-status/components/cases-filters/message-category-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/message-category-filters.hbs"}});
import { MESSAGE_CATEGORIES } from '../../constants/messages';
import BaseCasesFilterTemplate, {A, action} from './base-filter-template';

export default class CasesFiltersMessageCategoryFiltersComponent extends BaseCasesFilterTemplate {
	constructor() {
		super(...arguments);

		this.options = A([]);
		Object.entries(MESSAGE_CATEGORIES).forEach(([category, label], ix) => {
			this.options.pushObject({
				value: label,
				param: category,
				classNames: 'filter-md',
				checked: this.args.selections?.includes(category),
				index: ix,
			});
		});
		
	}

	@action
	handleOnChange(selections) {
		this.selections = A(selections);
		super.handleOnChange(selections);

		this.onChange({
			msgCategory: selections.mapBy('param'),
		});
	}
}