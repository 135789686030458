/** @format */

import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { storageFor } from 'ember-local-storage';

export default Route.extend(AuthenticatedRouteMixin, {
	preferences: storageFor('preferences'),
	currentUser: service('current-user'),
	thirdParty: service(),
	session: service(),
	_loadCurrentUser() {
		if (this.currentUser) {
			return this.currentUser.load().catch(() => this.session.invalidate());
		}
	},
	beforeModel() {
		this._super(...arguments);
		if (!this.currentUser.userChildren) {
			this._loadCurrentUser();
		}

		if (this.session.data.authenticated.requires_2fa) {
			this.transitionTo('two-factor-auth.verify');
		}
	},
	actions: {
		selectAccount(childId, childType) {
			this.set('session.data.authenticated.user_type', childType);
			this.set('session.data.authenticated.user_id', childId);
			this.set('preferences.selectedAccount', childId);
			this.set('preferences.selectedAccountType', childType);
			const redirectTo = this.get('thirdParty.redirectTo');
			const loginController = this.controllerFor('login');
			const previousTransition = loginController?.get('previousTransition');

			switch (childType) {
				case 'client':
					this.transitionTo('app.client.cases');
					break;
				case 'member':
					this.transitionTo('app.organization.cases');
					break;

				case 'attorney':
				case 'paralegal':
					if (redirectTo) {
						this.transitionTo(redirectTo);
					} else if (previousTransition) {
						previousTransition.retry();
					} else {
						this.transitionTo('app.firm.cases');
					}
					break;
				default:
					//* If the authenticated userType isn't one of our predefined four, invalidate the session and send them back to /login
					this.session.invalidate();
			}
		},
	},
});
