import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FilterDropdown2\n\t@title={{this.args.title}}\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='value'\n\t@onChange={{action 'handleOnChange'}}\n\t@hideFilterEnd={{true}}\n\t@modalType='Urgency'\n/>", {"contents":"<FilterDropdown2\n\t@title={{this.args.title}}\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='value'\n\t@onChange={{action 'handleOnChange'}}\n\t@hideFilterEnd={{true}}\n\t@modalType='Urgency'\n/>","moduleName":"case-status/components/cases-filters/message-urgency-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/message-urgency-filters.hbs"}});
import { URGENCY_MAP } from '../../constants/messages';
import BaseCasesFilterTemplate, {A, action} from './base-filter-template';

export default class CasesFiltersMessageUrgencyFiltersComponent extends BaseCasesFilterTemplate {
	constructor() {
		super(...arguments);

		this.options = A([]);
		URGENCY_MAP.forEach((label, lvl) => {
			this.options.pushObject({
				value: `${lvl} - ${label}`,
				param: lvl,
				classNames: 'filter-md',
				checked: this.args.selections?.includes(lvl),
			});
		});
	}

	@action
	handleOnChange(selections) {
		this.selections = A(selections);
		super.handleOnChange(selections);

		this.onChange({
			msgUrgency: selections.mapBy('param'),
		});
	}
}