/** @format */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dateDisplay } from 'case-status/helpers/date-display';
import { URGENCY_MAP } from '../constants/messages';

export default class FirmCaseCardComponent extends Component {
	@service customBranding;
	@service company;
	@service currentUser;
	@service ajax;
	@service activityTracking;
	@service router;

	constructor() {
		super(...arguments);
	}

	get newMessages() {
		// includes "messages" as well as chat notifications to match the inbox badge number
		let count = 0;
		if (this.args.notifications) {
			count += this.args.notifications.filterBy('readAt', null).length;
		}
		if (this.args.chatNotifications) {
			count += this.args.chatNotifications.filterBy('readAt', null).length;
		}
		return count;
	}

	get messageSentFormattedDate() {
		return this.args.theCase.mostRecentMessageSentAt
			? dateDisplay([
					this.args.theCase.mostRecentMessageSentAt,
					this.company.info,
					true,
			  ])
			: null;
	}
	get isClient() {
		return this.currentUser.user.type == 'client';
	}

	get isUserOnCase() {
		const theCase = this.args.theCase;
		const user = this.currentUser.user;

		return Boolean(theCase.get('users').find((usr) => usr.id == user.id));
	}

	get urgencyLabel() {
		const urgency = this.args.theCase.mostRecentMessageUrgency;
		const label = URGENCY_MAP.get(this.args.theCase.mostRecentMessageUrgency);
		return urgency && label ? `${urgency} - ${label}` : undefined;
	}

	@action
	goToCase() {
		this.router.transitionTo(this.args.targetRoute, this.args.theCase.id);
	}
}
