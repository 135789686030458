import ENV from 'case-status/config/environment';
import JSONAPIAdapter from '@ember-data/adapter/json-api';

import { inject as service } from '@ember/service';

/**
 * Adapter for handling two-factor requests.
 * Necessary due to the non JSON-API compliant response from the API.
 */
export default class TwoFactorAdapter extends JSONAPIAdapter {
  @service session;
  host = ENV.host;

  /**
   * Returns the headers for the requests, including the authorization token.
   * @returns {Object} The headers object.
   */
  get headers() {
    let access_token = this.session.data.authenticated.access_token;

    return {
      'Content-Type': 'application/vnd.api+json',
      'Authorization': `Bearer ${access_token}`,
      'Cache-Buster': new Date().getTime(),
    };
  }

  /**
   * Returns the path for the type.
   * @returns {string} The path for the type.
   */
  pathForType() {
    throw new Error('You must implement the method pathForType in your adapter');
  }

  /**
   * Returns the full URL for the requests.
   * @returns {string} The full URL.
   */
  get url() {
    return `${this.host}/${this.pathForType()}`;
  }

  /**
   * Returns the URL for creating a record.
   * @returns {string} The URL for creating a record.
   */
  urlForCreateRecord() {
    return this.url;
  }

  /**
   * Returns the URL for finding a record.
   * @returns {string} The URL for finding a record.
   */
  urlForFindRecord() {
    return this.url;
  }

  /**
   * Returns the URL for deleting a record.
   * @returns {string} The URL for deleting a record.
   */
  urlForDeleteRecord() {
    return this.url;
  }


  /**
   * Parse the response from the API. Given the API response is not JSON-API compliant,
   * we need to parse the error message and return it in the format Ember expects.
   * 
   * @param {*} status 
   * @param {*} headers 
   * @param {*} payload 
   * @param {*} requestData 
   * @returns 
   */
	handleResponse(status, headers, payload, requestData) {
		if (status === 400) {
      payload.errors = []
      payload.errors.push({
        detail: payload.message
      });
    }

		return super.handleResponse(...arguments);
	}
}
