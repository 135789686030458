/** @format */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';


export default Route.extend({
  session: service(),

	actions: {
		handleGoBack() {
      if (this.session.data.authenticated.requires_2fa) {
        this.session.invalidate();
			this.transitionTo('login');
		} else {
			this.transitionTo('app.firm.cases');
    }
	}
}
});
