import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

@classic
export default class AppTwoFactorAuthVerifyController extends Controller {
  @service store;
  @service login;
  @service session;
  @service notifications;
  @tracked verifyRequest = null;
  @tracked tokenRequestCount = 0;
  @tracked errorMessage = [];

	async init() {
    super.init(...arguments);
    this.createTwoFactorRequestToken = this.createTwoFactorRequestToken.bind(this);
    this.submitToken = this.submitToken.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);

    this.verifyRequest = this.store.createRecord('two-factor-verify', {
      code: '',
    });
  }

  /**
   * Create a new two-factor request token which will send a code to the user's email or phone
   * @returns {Promise<void>}
   */
  async createTwoFactorRequestToken() {
    try {
      const record = this.store.createRecord('two-factor-request-token', {});
      await record.save();
      this.tokenRequestCount += 1;
    } catch (error) {
      this.errorMessage = [this.getErrorMessage(error)];
      return;
    }
  }

  /**
   * Submit the two-factor token to the server to verify the user, on success, the user will be logged in
   * and redirected to their home page
   * 
   * @returns {Promise<void>}
   */
  async submitToken(evt) {
    if (evt) evt.preventDefault();
    try {
      await this.verifyRequest.save();
      this.set('session.data.authenticated.requires_2fa', false);
      
      this.login.login()
    } catch (error) {
      this.errorMessage = [this.getErrorMessage(error)];
      return;
    }
  }

  /**
   * Parse the error message from the server response
   * 
   * @param {*} error 
   * @returns the error message
   */
  getErrorMessage(error) {
    let errorMessage = 'An error occurred';
    if (error.errors && error.errors.length > 0) {
      const errorDetail = error.errors[0].detail;
      try {
        const parsedError = JSON.parse(errorDetail.replace(/'/g, '"'));
        errorMessage = parsedError.code ? parsedError.code[0] : errorDetail;
      } catch (parseError) {
        errorMessage = errorDetail;
      }
    }

    return errorMessage;
  }
}
