import ApplicationSerializer from './application';
import { inject as service } from '@ember/service';

export default class TwoFactorSerializer extends ApplicationSerializer {
	@service currentUser;

	/**
	 * Override the key for attributes to return the attribute as is. Necessary given
	 * `ApplicationSerializer`'s default behavior.
	 * @param {string} attr - The attribute name.
	 * @returns {string} The attribute name.
	 */
	keyForAttribute(attr) {
		return attr;
	}

}
