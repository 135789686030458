import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"activity-display-platform-icon\">\n\t<FaIcon @icon={{this.icon}} />\n</div>\n", {"contents":"<div class=\"activity-display-platform-icon\">\n\t<FaIcon @icon={{this.icon}} />\n</div>\n","moduleName":"case-status/components/activity-display-platform-icon.hbs","parseOptions":{"srcName":"case-status/components/activity-display-platform-icon.hbs"}});
import Component from '@glimmer/component';

/**
 * Component to display the platform icon based on the platform type.
 */
export default class ActivityDisplayPlatformIconComponent extends Component {
  /**
   * Returns the appropriate icon based on the platform type.
   * @returns {string} The icon name.
   */
  get icon() {
    const platformIconMapping = {
      web: 'laptop',
      android: 'android',
      ios: 'apple',
    };

    const platform = Object.keys(platformIconMapping).find(platform =>
      this.args.platform?.includes(platform)
    );

    return platform ? platformIconMapping[platform] : '';
  }
}
