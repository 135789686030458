import TwoFactorAdapter from './two-factor';

/**
 * Adapter for handling user two-factor authentication configurations.
 * Necessary due to the non JSON-API compliant response from the API.
 */
export default class UserTwoFaConfigurationsAdapter extends TwoFactorAdapter {
  /**
   * Returns the path for the type.
   * @returns {string} The path for the type.
   */
  pathForType() {
    return 'v3/user-2fa-configurations';
  }
}
