/** @format */

import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { observer, computed } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import { validator, buildValidations } from 'ember-cp-validations';
import Errors from '../../../../constants/errors';
import { defer } from 'rsvp';
import ENV from 'case-status/config/environment';
import subTabs from '../../../../constants/chat-sub-tabs';
import { A } from '@ember/array';

const Validations = buildValidations({
	newMessage: validator('presence', {
		presence: true,
		message: Errors.presenceError,
		description: 'Message',
	}),
});

export default Controller.extend(Validations, {
	// Services
	resourceMetadata: service(),
	customBranding: service(),
	currentUser: service(),
	notifications: service(),
	store: service(),
	ajax: service(),
	company: service(),
	thirdParty: service(),
	firmName: alias('company.info.name'),
	pubnub: service('pubnub-service'),
	
	//* Sub tabs
	subTabs: computed(function () {
		let currentSubTabs = [...subTabs];

		//* Remove Requests tab option
		const spliceIndex = currentSubTabs.indexOf('Requests');
		currentSubTabs.splice(spliceIndex, 1);

		//* Insert Checklist tab option
		if (this.theCase.firm.get('firmSettings.firmSettings').checklist) {
			const spliceIndex2 = currentSubTabs.indexOf('Documents');
			currentSubTabs.splice(spliceIndex2 + 1, 0, 'Checklist');
		}

		return currentSubTabs;
	}),

	showSubTabs: computed('subTabs.length', function () {
		return this.subTabs.length > 1;
	}),

	currentSubTab: 'Chat',

	isChatTab: computed('currentSubTab', function () {
		return this.currentSubTab === 'Chat';
	}),

	isDocumentsTab: computed('currentSubTab', function () {
		return this.currentSubTab === 'Documents';
	}),

	isChecklistTab: computed('currentSubTab', function () {
		return this.currentSubTab === 'Checklist';
	}),

	sortedFiles: alias('chat.chatFiles'),

	isLoadingChat: false,

	isUserOnCase: computed('theCase.users.[]', 'currentUser.user', function () {
		const theCase = this.theCase;
		const user = this.currentUser.user;

		return Boolean(theCase.get('users').find((usr) => usr.id == user.id));
	}),

	theCase: alias('model.theCase'),
	// Chat Properties
	chats: alias('model.internal'),
	chat: null, //* Default to the first in the list in lifecycle method

	chatMessageObserver: observer('chat.chatMessages.[]', function () {
		//* Only reload the chat because of message update once every second
		debounce(this, this.reloadChat, 1000);
	}),

	pendingChecklistItemCount: 0,

	chatChecklistItemObserver: observer(
		'chat.checklistItems.[].completedDate',
		async function () {
			const checkListItems = (await this.get('chat.checklistItems')) || [];
			const count = checkListItems.reduce((total, item) => {
				if (!item.completedDate) {
					if (!total) {
						return 1;
					} else {
						return total + 1;
					}
				} else {
					return total;
				}
			}, 0);

			this.set('pendingChecklistItemCount', count);
		},
	),

	authorizedChat: computed('chat.users.[]', 'currentUser.user', function () {
		const chatUsers = this.get('chat.users');
		const currentUser = this.get('currentUser.user');

		if (!chatUsers) return false;

		const currentUserAuthorized = chatUsers.find((user) => {
			return user.get('id') === currentUser.get('id');
		});
		return currentUserAuthorized;
	}),

	// Message Properties
	newMessage: '',
	showChatMessageError: false,
	newChatMessage: null,
	showChatMessageActionsModal: false,
	messagesAreLoading: true,
	messageSearchText: '',
	showMessageFilter: false,

	showUsersModal: false,

	modelObserver: observer('model', function () {
		if (!this.model) return;

		const caseChats = this.chats.toArray();
		const initialChat = caseChats[0];
		this.set('chat', initialChat);
	}),

	//* Viewport Properties
	inViewport: service(),
	loaderInViewPort: false,
	showLoader: computed('isLoadingMessages', 'loaderInViewPort', function () {
		return this.isLoadingMessages;
	}),

	didEnterViewPort() {
		if (this.chat?.get('chatMessages.length')) {
			this.set('loaderInViewPort', true);
			if (this.page || this?.meta?.total_pages) {
				this.fetchMoreChatMessages();
			}
		}
	},

	checkIfStillInViewPort() {
		//* In case someone is using a very large screen and 20
		//* never pushes the loader out of the viewport, then it
		//* can never technically enter the viewport again thus
		//* not calling fetchMoreCaseMessages and making it look
		//* like, to the user, that there are no more messages
		setTimeout(() => {
			if (this.loaderInViewPort) {
				this.fetchMoreChatMessages();
			}
		}, 500);
	},

	setupInViewport() {
		const loader = document.getElementById('loader');
		const viewportTolerance = { bottom: 20 };
		const { onEnter, onExit } = this.inViewport.watchElement(loader, {
			viewportTolerance,
		});

		onEnter(() => {
			this.didEnterViewPort();
		});

		onExit(() => {
			this.didExitViewPort();
		});
	},

	didExitViewPort() {
		this.set('loaderInViewPort', false);
	},

	async handleIncomingMessage(newMessage) {
		if (
			!newMessage ||
			(await newMessage?.chat?.get('id')) != this?.chat?.get('id')
		)
			return;

		//* Check to see if the message is already in the list
		const message = this.chatMessages.find((msg) => {
			return newMessage.id == msg.id;
		});

		if (message) return;

		this.chatMessages.unshiftObject(newMessage);
		return;
	},

	//* Chat Messages Properties
	size: 50,
	page: 1,
	meta: null,
	isLoadingMessages: true,
	chatMessages: A([]),
	initialization: function () {
		this.pubnub.newChatMessageCallbacks.push((...args) => {
			this.handleIncomingMessage(...args);
		});
	}.on('init'),
	chatChanged: observer('chat', function () {
		this.set('isLoadingMessages', true);
		debounce(this, 'fetchChatMessages', 500);
	}),

	fetchChatMessages() {
		const chatId = this.chat?.id;
		if (!chatId) return;
		this.set('page', 1);

		const params = {
			page: {
				number: this.page,
				size: this.size,
			},
		};

		this.set('isLoadingMessages', true);
		this.set('queryParams', params);

		this.store
			.query(`chat_message`, { chatId, params })
			.then((res) => {
				//* Set the meta
				this.set('meta', res.meta);

				//* Clear the old chatMessages
				this.chatMessages.clear();

				//* Push in the new chatMessages
				this.chatMessages.pushObjects(res.toArray());

				//* Set the chatMessage loading state to false
				this.set('isLoadingMessages', false);
			})
			.finally(() => {
				next(() => {
					this.set('isLoadingMessages', false);
					this.checkIfStillInViewPort();
				});
			});
	},

	fetchMoreChatMessages() {
		if (
			!this.meta ||
			this.meta.total_pages <= this.page ||
			this.meta.total_results <= this.chatMessages.length
		)
			return;
		const chatId = this.chat?.id;
		if (!chatId) return;
		this.incrementProperty('page');

		const params = {
			page: {
				number: this.page,
				size: this.size,
			},
		};

		this.set('isLoadingMessages', true);
		this.set('queryParams', params);

		this.store
			.query(`chat_message`, { chatId, params })
			.then((res) => {
				//* Set the meta
				this.set('meta', res.meta);

				//* Push in the new chatMessages
				this.chatMessages.pushObjects(res.toArray());

				//* Set the chatMessage loading state to false
				this.set('isLoadingMessages', false);
			})
			.finally(() => {
				next(() => {
					this.set('isLoadingMessages', false);
					this.checkIfStillInViewPort();
				});
			});
	},

	//* Custom Methods *//

	reloadChat() {
		const chat = this.chat;
		if (chat) {
			chat.reload();
		}
	},

	debounceReloadChat(t) {
		debounce(this, 'reloadChat', t != undefined ? t : 3000);
	},

	actions: {
		async markChatNotificationsAsRead(result = defer()) {
			// Endpoint for setting read_at for chat_notifications, will only
			// mark notifications for current user as read
			let url = `${ENV.host}/chats/${this.chat.id}/relationships/chat_notifications/mark_as_read`;
			try {
				await this.ajax.post(url);
				this.set('errors', []);

				const theCase = this.theCase;
				// Set 'unread' count next to Case Tab to zero
				theCase.set('newInternalMessages', 0);

				// We set the chat notification read at date for local objects
				const chatNotifications =
					this.chat.get('chatNotifications').toArray() || [];
				for (let i = 0; i < chatNotifications.length; i++) {
					const chatNotification = chatNotifications[i];
					chatNotification.set('readAt', new Date()); // 	// });
				}
				// Now that these notifications are set as read, recalculate the notifications that we use to
				// set the 'unread count' next to Chat tab
				this.chat.calculateUnreads();
			} catch (err) {
				this.notifications.error(Errors.mapResponseErrors(err), {
					canClose: true,
					autoClear: true,
					clearDuration: 5000,
				});
				result.reject();
			}

			return result.promise;
		},

		prepareChatMessage(result = defer()) {
			const chat = this.chat;
			const chatId = chat.id;
			const message = this.newMessage;

			result = result.promise
				? result
				: result.result
				? result.result
				: defer();

			this.send('sendChatMessage', result, chatId, message);
		},

		removeCollaborator() {
			const caseId = this.get('theCase.id');
			const orgId = this.get('chat.organizationId');

			const url = `${ENV.host}/cases/${caseId}/organizations/${orgId}`;

			return this.ajax.delete(url).then((res) => {
				//* Deep Clone the response to pass to the store
				//* to preserve the properties on the response since
				//* the store will alter the response object since we
				//* were passing a reference object.
				const payload = Object.assign({}, res);
				//* Push payload to store to update data
				this.store.pushPayload(payload);
				//* peek the newly updated case data and set it on the model in place of the old data
				const theCase = this.store.peekRecord('case', res.data.id);
				this.set('theCase', theCase);
			});
		},

		requestSuccess() {
			this.send('toggleShowNewRequestModal');
			this.notifications.success('Your request has been sent successfully!', {
				autoClear: true,
			});
			setTimeout(() => {
				this.chat.fetchFormResponses();
			}, 5000); // 5 seconds
		},

		selectSubTab(tab = '') {
			const subTabs = this.subTabs;
			const isValidTab = subTabs.includes(tab);

			if (isValidTab) {
				if (tab == 'Requests') {
					//* Go ahead and get the latest set of requests when going to this tab
					this.chat.fetchFormResponses();
				}
				this.set('currentSubTab', tab);
			}
		},

		sendChatMessage(result = defer(), chatId = 0, content = '') {
			result = result.promise
				? result
				: result.result
				? result.result
				: defer();
			if (chatId && content) {
				this.send('postChatMessage', chatId, content, result);
				result.promise.then((msg) => {
					this.chatMessages.unshiftObject(msg);
				});
			} else {
				result.reject();
			}
		},

		toggleShowMessageActionsModal() {
			this.toggleProperty('showMessageActionsModal');
		},

		toggleShowUsersModal() {
			this.toggleProperty('showUsersModal');
		},

		toggleWritingMessage() {
			this.toggleProperty('isWritingMessage');
		},

		validateNewMessage(result = defer()) {
			result = result.promise
				? result
				: result.result
				? result.result
				: defer();
			if (
				this.get('validations.attrs.newMessage.message') &&
				this.isWritingMessage
			) {
				this.set('showMessageError', true);
				return result.reject();
			} else {
				this.send('prepareChatMessage', result);
				return result.promise
					.then(() => {
						this.setProperties({
							isWritingMessage: false,
							newMessage: '',
							showMessageError: false,
						});
					})
					.catch(() => {
						this.set('showMessageError', true);
					});
			}
		},

		showLightboxImage(img) {
			this.set('lightboxImage', img);
		},

		postFile(formData, result) {
			formData.append('chat_id', this.get('chat.id'));

			const url = `${ENV.host}/chat_files`;
			const data = formData;

			this.ajax
				.post(url, {
					processData: false,
					contentType: false,
					data: data,
				})
				.then(() => {
					this.fetchChatMessages();
					return result.resolve();
				})
				.catch((response) => {
					return result.reject(Errors.mapResponseErrors(response));
				});
		},

		hideLightboxImage() {
			this.set('lightboxImage', null);
		},
		refreshChat() {
			this.debounceReloadChat();
		},
	},
});
