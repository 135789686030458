import Route from '@ember/routing/route';
import { v4 } from 'uuid';

export default class AppTwoFactorAuthVerifyRoute extends Route {
	async model() {
		let model;
		try {
			model = await this.store.findRecord('user-two-fa-configuration', v4());
		} catch (error) {
			this.transitionTo('two-factor-auth.configure');
		}

		return model;
	}

	async setupController(controller, model) {
		super.setupController(...arguments);
		controller.set('model', model);

		if (model) {
			await controller.createTwoFactorRequestToken();
		}
	}
}
