const SENTIMENT_LABELS = [
	'Strongly Negative',
	'Negative',
	'Neutral',
	'Positive',
	'Strongly Positive'
];

/**
 * 
 * @param {float} value The sentiment value of the classified message
 * @returns The human-readable string corresponding to the sentiment value.
 * This is done by finding the upper threshold of each category based on the number
 * of categories and iterating over them in order until we find the threshold that
 * `value` is within.
 */
export const SENTIMENT_MAPPER = (value) => {
	const multiplier = 1.0 / SENTIMENT_LABELS.length;
	for (let [index, label] of SENTIMENT_LABELS.entries()) {
		const threshold = (index + 1) * multiplier;
		if (value <= threshold) {
			return label;
		}
	}
}

const URGENCY_LABELS = [
	'Low',
	'Below Avg',
	'Moderate',
	'High',
	'Critical'
];

// iterates over `URGENCY_LABELS` in order creating a Map of (int) level => (string) label
export const URGENCY_MAP = new Map(URGENCY_LABELS.map((label, index) => [(index+1), label]));

// map db name to UI label
export const MESSAGE_CATEGORIES = {
	'inquiry': 'Inquiry',
	'information provision': 'Information',
	'notification': 'Notification',
	'feedback': 'Feedback',
	'confirmation': 'Confirmation',
	'miscellaneous': 'Miscellaneous',
};

export const URGENCY_DESCRIPTIONS = {
	'1 - Low': 'Not urgent. Likely to not require a response. (thank you, general update).',
	'2 - Below Average': 'Needs a reply but not time-sensitive (information, scheduling request in advance).',
	'3 - Moderate': 'Should be addressed soon (request for update, concerns).',
	'4 - High': 'Needs prompt attention (imminent deadline, client in distress).',
	'5 - Critical': 'Immediate action required (emergency, legal risks).',
};

export const CATEGORY_DESCRIPTIONS = {
	'Inquiry': 'Client asks a question or requests information.',
	'Information': 'Client shares information without expecting a response.',
	'Notification': 'Updates on changes; may need acknowledgment.',
	'Feedback': 'Client opinions or service-related comments.',
	'Confirmation': 'Client confirms receipt or understanding.',
	'Miscellaneous': 'Admin or uncategorized messages.',
};