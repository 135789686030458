/** @format */

import EmberRouter from '@ember/routing/router';
import config from 'case-status/config/environment';

export default class Router extends EmberRouter {
    location = config.locationType;
    rootURL = config.rootURL;
}

Router.map(function () {
  this.route('e-sign', { path: 'signed_document/:uuid' });
  this.route('login', {
      queryParams: ['username', 'pin', 'is_platform_redirect'],
  });
  this.route('forgot-password');
  this.route('forgot-password-success');
  this.route('reset-password', { path: '/reset-password/:token' });
  this.route('reset-password-success');
  this.route('implementation');

  this.route('select-account');
  this.route('restore');

  this.route('app', function () {
    // The 'error' route is what is rendered if a 404 error bubbles up and hits it
    this.route('error', { path: 'error/:something' });

    // The 'loading' route is rendered until the afterModel hook is finished, it bubbles up
    this.route('loading');

    this.route('clio', { queryParams: ['code', 'state'] });
    this.route('add-to-clio');
    this.route('mycase', { queryParams: ['code'] });
    this.route('smokeball', { queryParams: ['code'] });
    this.route('welcome', function () {});

    this.route('client', function () {
        this.route('cases', { queryParams: ['showMobile'] });
        this.route('case', { path: '/cases/:case_id' }, function () {
            this.route('info');
            this.route('appointments');
            this.route('treatments');
        });
    });

    this.route('organization', function () {
        this.route('cases');
        this.route('case', { path: '/cases/:case_id' });

        this.route('settings', function () {
            this.route('account');
            this.route('organization');
        });
        this.route('requests');
        this.route('inbox');
    });
    this.route('admin', function () {
        this.route('implementation', function () {
            this.route('firm');
            this.route('organization');
        });
    });
    this.route('firm', function () {
        this.route('cases', {
            queryParams: ['caseIds', 'newClientMessages', 'newInternalMessages'],
        });
        this.route('case', { path: '/cases/:case_id' }, function () {
            this.route('info');
            this.route('collaboration');
            this.route('appointments');
            this.route('treatments');
            this.route('internal');
            this.route('messages');
        });
        this.route('inbox');
        this.route('settings', function () {
            this.route('account');
            this.route('case-types');
            this.route('integrations');
            this.route('firm');
            this.route('message-templates');
            this.route('users');
            this.route('templates', function () {
                this.route('message-templates');
                this.route('checklist-templates');
            });
        });
        this.route('clients');
        this.route('firm-insights');
        this.route('accounting', function () {
            this.route('invoices');
            this.route('case_charges');
        });

        this.route('import', function () {
            this.route('case');
        });
    });
    this.route('litify', { queryParams: ['code'] });
    this.route('salesforce', { queryParams: ['code'] });
    this.route('docketwise', { queryParams: ['code'] });
    this.route('practicepanther', { queryParams: ['code', 'state'] });

    this.route('third_party', function () {
        this.route('case', { path: '/case/:something' }, function () {
            // index route handles the base rendering, went ahead and structured to allow sub-routes in the future
        });
        // the 'error' route is what is displayed if a 404 error bubbles up and hits it
        this.route('error', { path: 'error/:something' });
    });
    this.route('merus-case');
    this.route('files', { path: '/files/:file_id' });
    this.route('videos', { path: '/videos/:video_id' });
  });

  this.route('sandbox', function () {
      this.route('inputs', { path: '/' });
      this.route('cards');
      this.route('buttons');
      this.route('form-elements');
      this.route('iframe');
      this.route('mobile');
  });
  this.route('registration');
  this.route('privacy');
  this.route('terms-of-service');
  this.route('download');

  // the 'not-found' route is transitioned to when a route that isn't declared here is attempted to navigate to
  this.route('not-found', { path: '/*path' });
  this.route('logout');
  this.route('login-admin', {
      path: 'support_sign_in',
      queryParams: ['username', 'pin', 'is_platform_redirect'],
  });
  this.route('bulk-action', { path: '/bulk_action/:bulk_action_id' }, function () {
      this.route('validation');
  });

  this.route('two-factor-auth', function() {
    this.route('configure');
    this.route('verify');
  });
});
