import TwoFactorAdapter from './two-factor';

export default class TwoFactorRequestTokenAdapter extends TwoFactorAdapter {
  /**
   * Returns the path for the type.
   * @returns {string} The path for the type.
   */
  pathForType() {
    return 'v3/2fa/request-token';
  }	
}
