import Service from '@ember/service';
import { inject as service } from '@ember/service';
import ENV from 'case-status/config/environment';
import { set } from '@ember/object';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';

export default class ChromeExtensionService extends Service {
	@service session;
	@service company;

	chromeExtensionId = ENV.chromeExtensionId;
	host = ENV.host;
	canReceiveMessages;

	constructor() {
		super(...arguments);
		window.chrome?.runtime?.sendMessage(
			this.chromeExtensionId,
			{ type: 'ping' },
			this.ping.bind(this),
		);
	}

	ping(response) {
		if (response.message === 'pong') {
			set(this, 'canReceiveMessages', true);
		}
	}

	get _active() {
		if (!this.canReceiveMessages) {
			window.chrome?.runtime?.sendMessage(
				this.chromeExtensionId,
				{ type: 'ping' },
				this.ping.bind(this),
			);
		}
		return (
			this.canReceiveMessages && window.parent.location !== window.location
		);
	}

	sendUnreadCount(count) {
		try {
			this.sendMessage(count, 'unread-count');
		} catch (error) {
			console.warn(error);
		}
	}

	sendLoggedOut() {
		try {
			// we want the logged out message to be sent from non-chrome extension
			const broadCastFromAnywhere = true;
			this.sendMessage('false', 'logged-in', broadCastFromAnywhere);
		} catch (error) {
			console.warn(error);
		}
	}

	sendLoggedIn() {
		// we want the logged in message to be sent from non-chrome extension
		const broadCastFromAnywhere = true;
		const authenticated = this.session.data.authenticated;
		const caseLabel = dynamicCaseLabel([this.company]);
		const extensionState = {
			access_token: authenticated.access_token,
			refresh_token: authenticated.refresh_token,
			user_type: authenticated.user_type,
			user_id: authenticated.user_id,
			case_label: caseLabel,
		};
		this.sendMessage(extensionState, 'logged-in', broadCastFromAnywhere);
	}

	sendMessage(content, type, broadCastFromAnywhere = false) {
		if (
			(!this._active && !broadCastFromAnywhere) ||
			!window.chrome?.runtime?.sendMessage ||
			!this.chromeExtensionId
		) {
			return;
		}

		window.chrome.runtime.sendMessage(this.chromeExtensionId, {
			type: type,
			content: content,
		});
	}
}
