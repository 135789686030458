import Model, { attr } from '@ember-data/model';

/**
 * TwoFactorVerifyModel
 * 	
 * The interface to use to send the code to the server to verify the user's identity
 */
export default class TwoFactorVerifyModel extends Model {
	/**
	 * Six-digit code to verify the user's identity
	 */
	@attr('string') code;
}
