import EmberError from '@ember/error';
import moment from 'moment';

export const BULK_MESSAGE_SCHEDULED_DATE_FORMAT = 'dddd MMMM Do, YYYY [at] h:mma z';

export function tzAbvFromDate(dateOrTZ) {
	//* Check if Date
	const isDate = dateOrTZ && typeof dateOrTZ.getMonth === 'function';

	//* Check if string
	const isString = typeof dateOrTZ === 'string';

	if (!isDate && !isString) {
		throw new EmberError(
			'tzAbv requires a Date object or String be passed to it.',
		);
	}

	let zone;

	if (isDate) {
		zone = dateOrTZ
			.toLocaleTimeString('en-us', { timeZoneName: 'short' })
			.split(' ')[2];

		return zone;
	}

	if (isString) {
		zone = moment.tz.zone(dateOrTZ).abbr(360);

		return zone;
	}
}
