import TwoFactorSerializer from './two-factor';
import { v4 } from 'uuid';

export default class TwoFactorRequestTokenSerializer extends TwoFactorSerializer {
	/**
	 * Normalize the response from the API to match the JSON:API format.
	 * @param {DS.Store} store - The store instance.
	 * @param {DS.Model} primaryModelClass - The primary model class.
	 * @param {Object} payload - The payload from the API.
	 * @param {string|number} id - The ID of the record.
	 * @param {string} requestType - The request type.
	 * @returns {Object} The normalized response.
	 */
	normalizeResponse(store, primaryModelClass, payload, id, requestType) {
		payload.data = {
			id: this.currentUser?.user?.userId || v4(),
			type: 'two-factor-request-token',
			attributes: {},
		};

		delete payload.message;

		return super.normalizeResponse(...arguments);
	}
}
