import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Errors from 'case-status/constants/errors';


export default class BulkActionController extends Controller {
	@service store;
	@tracked content;
	@tracked validated;
	@tracked caseCount;
	@tracked attachmentName;
	@tracked errors;

	get description() {
		return `The following message will be sent to the clients of <strong>${this.caseCount} case${this.caseCount === 1 ? '' : 's'}</strong> from the previously uploaded CSV`;
	}

	get successMessage() {
		return 'Success! We are sending messages on your selected cases';
	}

	get btnText() {
		return this.validated ? 'Close' : 'Cancel'
	}

	get showSendButton() {
		// should only show send button if not already validated and there are no errors
		// ~A && ~B == ~(A || B)
		return !(this.validated || this.errors?.length);
	}

	@action
	submit() {
		this.model.set('validatedAt', new Date());
		this.errors = [];
		this.model.save()
			.then(() => {
				this.validated = true;
			})
			.catch((err) => {
				this.errors = Errors.mapResponseErrors(err);
			})
	}

	@action
	cancel() {
		window.close();
	}
}