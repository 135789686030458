import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FilterDropdown2\n\t@title={{this.args.title}}\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='value'\n\t@onChange={{action 'handleOnChange'}}\n/>", {"contents":"<FilterDropdown2\n\t@title={{this.args.title}}\n\t@options={{options}}\n\t@selections={{selections}}\n\t@sortKey='value'\n\t@onChange={{action 'handleOnChange'}}\n/>","moduleName":"case-status/components/cases-filters/message-filters.hbs","parseOptions":{"srcName":"case-status/components/cases-filters/message-filters.hbs"}});
import BaseCasesFilterTemplate, { A, action } from './base-filter-template';

export default class CasesFiltersMessageFiltersComponent extends BaseCasesFilterTemplate {
	constructor() {
		super(...arguments);

		const options = A([
			{
				value: 'Any Unread Messages',
				imgSrc: 'img/new-messages.svg',
				alt: 'new messages',
				classNames: 'filter-lg',
				param: 'anyNewMessages',
			},
			{
				value: 'Unread Client Messages',
				imgSrc: 'img/new-messages.svg',
				alt: 'new messages',
				classNames: 'filter-lg',
				param: 'newClientMessagesRaw',
			},
			{
				value: 'Unread Internal Messages',
				imgSrc: 'img/new-messages.svg',
				alt: 'new messages',
				classNames: 'filter-lg',
				param: 'newInternalMessagesRaw',
			},
			{
				value: 'Unread Collaboration Messages',
				imgSrc: 'img/new-messages.svg',
				alt: 'new messages',
				classNames: 'filter-lg',
				param: 'newCollaborationMessages',
			},
		]);

		if (this.permissions.get('user.filter_firm_unread_messages')) {
			options.pushObject({
				value: 'Any Unread Client Messages (All Users)',
				imgSrc: 'img/new-messages.svg',
				alt: 'new messages',
				classNames: 'filter-xl',
				param: 'anyUserHasUnreadClientMessages',
			});
		}
		options.forEach((option) => {
			option.checked = this.args.selections?.includes(option.param);
		});

		this.options = options;
	}

	@action
	handleOnChange(selections = []) {
		//handle how it will be set on the cases controller and format that as an object
		super.handleOnChange(selections);

		this.onChange({
			bulkMsg: selections.mapBy('param'),
		});
	}
}
