export const FEATURE_GATE_ENVIRONMENT_DEVELOPMENT = 'development';
export const FEATURE_GATE_ENVIRONMENT_STAGING = 'staging';
export const FEATURE_GATE_ENVIRONMENT_PRODUCTION = 'production';

export const UNRECOGNIZED_ERROR = 'Unrecognized';
export const UNINITIALIZED_ERROR = 'Uninitialized';
export const GENERIC_ERROR = 'Error';

export const LAUNCH_INTERCOM = 'launch_intercom';
export const LAUNCH_LOGIN_TO_PDI = 'launch_login_to_pdi';
export const LAUNCH_NEW_RECOMMENDED_RESPONSES_MODEL = 'launch_new_recommended_responses_model';
export const LAUNCH_TWO_FA = 'launch_2fa';
export const GATES = {
	[LAUNCH_INTERCOM]: LAUNCH_INTERCOM,
	[LAUNCH_LOGIN_TO_PDI]: LAUNCH_LOGIN_TO_PDI,
	[LAUNCH_NEW_RECOMMENDED_RESPONSES_MODEL]: LAUNCH_NEW_RECOMMENDED_RESPONSES_MODEL,
	[LAUNCH_TWO_FA]: LAUNCH_TWO_FA
};