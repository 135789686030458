import Model from '@ember-data/model';

/**
 * TwoFactorRequestTokenModel
 * 
 * Model for the two-factor request token, which when created will send a code to the user's phone
 */
export default class TwoFactorRequestTokenModel extends Model {

}
