/** @format */

import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { defer } from 'rsvp';
import { resetTableLocation } from 'case-status/components/cases-table';

export default Component.extend({
	showDropdown: false,
	icon: 'gear',

	options: null,
	topLeftOptions: null,
	bottomLeftOptions: null,
	topRightOptions: null,
	bottomRightOptions: null,

	selectedOptions: computed(function () {
		const options = this.options || [];
		return options.filter((opt) => {
			return opt.selected;
		});
	}),

	showDropdownObserver: observer('showDropdown', function () {
		if (this.showDropdown === false) {
			this.setup();
		}
	}),

	init() {
		this._super(...arguments);
		this.setup();
	},

	setup() {
		const columnOptions = this.get('extra.columnOptionsArray');
		const columns = this.get('extra.columns');
		const topLeft = [];
		const bottomLeft = [];
		const topRight = [];
		const bottomRight = [];

		const colOpts = columnOptions.map((opt) => {
			const match = columns.find((col) => {
				if (opt.label && col.label && opt.label === col.label) {
					return true;
				} else if (opt.title && col.title && col.title === opt.title) {
					return true;
				} else {
					return false;
				}
			});

			if (opt.set) {
				if (opt.isStatic) {
					opt.set('selected', true);
					opt.set('disabled', true);
				} else if (match) {
					opt.set('selected', true);
					opt.set('disabled', false);
				} else {
					opt.set('selected', false);
					opt.set('disabled', false);
				}
			} else {
				if (opt.isStatic) {
					opt.selected = true;
					opt.disabled = true;
				} else if (match) {
					opt.selected = true;
					opt.disabled = false;
				} else {
					opt.selected = false;
					opt.disabled = false;
				}
			}

			switch (opt.group) {
				case 'top-left':
					topLeft.push(opt);
					break;
				case 'bottom-left':
					bottomLeft.push(opt);
					break;
				case 'top-right':
					topRight.push(opt);
					break;
				case 'bottom-right':
				default:
					bottomRight.push(opt);
			}

			return opt;
		});

		this.set('options', colOpts);
		this.set('topLeftOptions', topLeft);
		this.set('bottomLeftOptions', bottomLeft);
		this.set('topRightOptions', topRight);
		this.set('bottomRightOptions', bottomRight);
	},

	actions: {
		toggleShowDropdown() {
			this.toggleProperty('showDropdown');
		},

		updateTheColumns() {
			const result = defer();
			const selectedOpts = this.selectedOptions || [];
			const updateColumns = this.get('tableActions.updateColumns');

			this.set('showDropdown', false);

			if (selectedOpts.length >= 3) {
				if (updateColumns && typeof updateColumns === 'function') {
					updateColumns(selectedOpts);
				}
			}

			resetTableLocation();
			return result.promise;
		},
	},
});
