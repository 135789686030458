/** @format */

import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import { inject as service } from '@ember/service';

export default Route.extend(UnauthenticatedRouteMixin, {
	chromeExtension: service(),
	router: service(),
	session: service(),
	thirdParty: service(),
	routeIfAlreadyAuthenticated: null, // Making null because the plugin's default is /login unless overridden, will handle in the setupController hook instead
	serviceWorker: service('service-worker'),
	loginService: service('login'),
	currentUser: service('current-user'),
	beforeModel() {
		this._super(...arguments);
		//* Clear the store
		this.store.unloadAll();
	},

	setupController(controller) {
		this._super(...arguments);
		const integrationType =
			controller.integrationType || this.get('thirdParty.integrationType');
		const integrationId =
			controller.integrationId || this.get('thirdParty.integrationId');
		const redirectTo =
			controller.redirectTo || this.get('thirdParty.redirectTo');

		if (controller.integrationType && controller.integrationId) {
			this.set('thirdParty.hasParams', true);
		}

		if (
			integrationType &&
			integrationId &&
			((!this.thirdParty.integrationType && !this.thirdParty.integrationId) ||
				this.thirdParty.integrationId !== integrationId ||
				this.thirdParty.integrationType !== integrationType)
		) {
			this.thirdParty.setProperties({
				integrationType,
				integrationId,
			});
		} else if (integrationType && !integrationId) {
			this.thirdParty.setProperties({
				integrationType,
			});
		}

		if (this.session.isAuthenticated) {
			if (redirectTo) {
				this.router.transitionTo(redirectTo);
			} else {
				if (
					integrationType &&
					integrationId &&
					this.thirdParty.shouldRedirect
				) {
					this.replaceWith(`app.third_party.case`, integrationId);
				} else {
					this.replaceWith(
						`${ENV.routeAfterAuthentication}`
							.replace(/\//g, '.')
							.replace('.', ''),
					);
				}
			}
		} else {
			this.chromeExtension.sendLoggedOut();
		}
	},

	actions: {
		login(email, password, result, previousTransition) {
			this.session
				.authenticate('authenticator:oauth2', email, password)
				.then(() => {
					//* The authenticate method fires and renders the loginService method useless
					//* It was originally added in case the authenticate didn't fire correctly
					// loginService needs to know number of sub-users, so if not loaded load up the current user
					if (this.currentUser) {
						this.currentUser.load().then(() => this.loginService.login(previousTransition));
					} else {
						this.loginService.login(previousTransition);
					}
				})
				.catch((err) => {
					if (!err) {
						this.serviceWorker.set('internetDownLogin', true);
						result.reject();
					} else if (err && err['errors'] && err['errors'][0]['status'] > 500) {
						this.serviceWorker.set('serverDownLogin', true);
						result.reject();
					} else {
						this.controller.set('errors', [
							'Your username and password combination is not correct. Please try again.',
						]);
						result.reject();
					}
				});
		},
	},
});
