export const acceptedFileTypes =
	'.pdf,.png,.jpg,.jpeg,.mov,.mp4,.doc,.docx,.pages,.heic';
export const videoFileTypes = '.mov,.mp4';
export const imgExtensions = ['jpg', 'jpeg', 'png'];
export const csvTypes = '.csv,.xls,.xlsx';

export const chunkSize = 2000000; // ~2MB
export const maxFilesize = 10000000; // ~10MB

export default {
	acceptedFileTypes,
	chunkSize,
	maxFilesize,
	videoFileTypes,
	imgExtensions,
	csvTypes,
};
