import TwoFactorAdapter from './two-factor';

export default class TwoFactorVerifyAdapter extends TwoFactorAdapter {
	/**
	 * Returns the path for the type.
	 * @returns {string} The path for the type.
	 */
	pathForType() {
		return 'v3/2fa/verify';
	}
}
