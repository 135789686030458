/** @format */

import Service, { inject as service } from '@ember/service';

export default Service.extend({
	router: service(),
	session: service(),
	thirdParty: service(),
	currentUser: service('current-user'),
	login(previousTransition) {
		const userType = this.get('session.data.authenticated.user_type');
		const requiresTwoFA = this.get('session.data.authenticated.requires_2fa');
		const integrationType = this.get('thirdParty.integrationType');
		const integrationId = this.get('thirdParty.integrationId');
		const shouldRedirect = this.get('thirdParty.shouldRedirect');
		const redirectTo = this.get('thirdParty.redirectTo');

		// if user has multiple sub-users, we should not retry the transition until the
		// desired sub-user has been selected
		const userChildren = this.currentUser?.usersChildren || [];
		const retryPreviousTransition = !!previousTransition && userChildren.length < 2;
		if (requiresTwoFA) {
			this.router.transitionTo('two-factor-auth.verify');
			return;
		}

		switch (userType) {
			case 'client':
				this.router.transitionTo('app.client.cases');
				break;
			case 'member':
				this.router.transitionTo('app.organization.cases');
				break;
			//* attorney and paralegal apply the same logic
			case 'attorney':
			case 'paralegal':
				if (integrationType && integrationId && shouldRedirect) {
					this.router.transitionTo(`app.third_party.case.index`, integrationId);
				} else if (retryPreviousTransition ) {
					previousTransition.retry();
				} else {
					const location = redirectTo || 'app.firm.cases';
					if (integrationType) {
						this.router.transitionTo(location, {
							queryParams: { integrationType: integrationType },
						});
					} else {
						this.router.transitionTo(location);
					}
				}
				break;
			default:
				//* If the authenticated userType isn't one of our predefined four, invalidate the session and send them back to /login
				this.session.invalidate();
				break;
		}
	},
});
