import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Model.extend({
	name: attr('string'),
	reviewLink: attr('string'),
	hoursEnd: attr('number'),
	hoursStart: attr('number'),
	isEu: attr('boolean'),
	isLawFirm: alias('includeCaseManagers'),
	includeCaseManagers: attr('boolean'),
	onDemandFirm: attr('boolean'),
	lastImported: attr('string'),
	phoneNumberRegion: attr('string'),
	trialExpired: attr('boolean'),
	deactivated: attr('boolean'),
	hasInvoices: attr('boolean'),

	usesAbacus: attr('boolean'),
	usesClio: attr('boolean'),
	usesLitify: attr('boolean'),
	usesSalesforce: attr('boolean'),
	usesMerusCase: attr('boolean'),
	usesCasePeer: attr('boolean'),
	usesFilevine: attr('boolean'),
	usesMyCase: attr('boolean'),
	usesSmartAdvocate: attr('boolean'),
	usesNeos: attr('boolean'),
	usesSmokeball: attr('boolean'),
	usesDocketwise: attr('boolean'),
	usesPracticePanther: attr('boolean'),

	clientId: attr('string'),
	hasStripeIntegration: attr('boolean'),
	timezone: attr('string'),
	isCorporate: attr('boolean'),
	caseLabelSingular: attr('string'),
	caseLabelPlural: attr('string'),
	createdAt: attr('date'),
	reauthDate: attr('date'),
	messagingOff: attr('date'),
	inviteMessage: attr('string'),
	inviteLink: attr('string'),
	reviewMessage: attr('string'),
	autoResponderEnabled: attr('boolean'),
	afterHoursMessage: attr('string'),
	dashboardUrl: attr('string'),
	trainingPackage: attr('string'),
	walkmeEnabled: attr('string'),

	usesIntegration: computed(
		'usesAbacus',
		'usesClio',
		'usesLitify',
		'usesFilevine',
		'usesMerusCase',
		'usesCasePeer',
		'usesMyCase',
		'usesSmartAdvocate',
		'usesSalesforce',
		'usesNeos',
		'usesSmokeball',
		'usesDocketwise',
		'usesPracticePanther',
		function () {
			const usesAbucus = this.usesAbacus;
			const usesClio = this.usesClio;
			const usesLitify = this.usesLitify;
			const usesMerusCase = this.usesMerusCase;
			const usesFilevine = this.usesFilevine;
			const usesCasePeer = this.usesCasePeer;
			const usesMyCase = this.usesMyCase;
			const usesSmartAdvocate = this.usesSmartAdvocate;
			const usesSalesforce = this.usesSalesforce;
			const usesNeos = this.usesNeos;
			const usesSmokeball = this.usesSmokeball;
			const usesDocketwise = this.usesDocketwise;
			const usesPracticePanther = this.usesPracticePanther;
			return (
				usesAbucus ||
				usesClio ||
				usesLitify ||
				usesMerusCase ||
				usesFilevine ||
				usesCasePeer ||
				usesMyCase ||
				usesSmartAdvocate ||
				usesSalesforce ||
				usesNeos ||
				usesSmokeball ||
				usesDocketwise || 
				usesPracticePanther
			);
		},
	),

	integrationPartner: computed(
		'usesFilevine',
		'usesClio',
		'usesAbacus',
		'usesLitify',
		'usesMerusCase',
		'usesCasePeer',
		'usesMyCase',
		'usesSalesforce',
		'usesNeos',
		'usesSmartAdvocate',
		'usesSmokeball',
		'usesDocketwise',
		'usesPracticePanther',
		function () {
			if (this.usesFilevine) {
				return 'Filevine';
			} else if (this.usesClio) {
				return 'Clio';
			} else if (this.usesLitify) {
				return 'Litify';
			} else if (this.usesAbacus) {
				return 'Abacus';
			} else if (this.usesMerusCase) {
				return 'MerusCase';
			} else if (this.usesCasePeer) {
				return 'CASEpeer';
			} else if (this.usesMyCase) {
				return 'MyCase';
			} else if (this.usesSmartAdvocate) {
				return 'SmartAdvocate';
			} else if (this.usesSalesforce) {
				return 'Salesforce';
			} else if (this.usesNeos) {
				return 'Neos';
			} else if (this.usesSmokeball) {
				return 'Smokeball';
			} else if (this.usesDocketwise) {
				return 'Docketwise';
			} else if (this.usesPracticePanther) {
				return 'PracticePanther';
			} else {
				return null;
			}
		},
	),

	//* Organization members only properties
	userCount: attr('number'),
	userCountDisplay: computed('userCount', function () {
		return this.userCount - 3;
	}),
	previewUsers: attr(),
	activeCasesCount: attr('number'),
	attorneyLabelSingular: attr('string'),
	attorneyLabelPlural: attr('string'),
	caseManagerLabelSingular: attr('string'),
	caseManagerLabelPlural: attr('string'),

	//* Relationships
	caseStatuses: hasMany('case-status'),
	customBranding: belongsTo('custom-branding'),
	caseTypes: hasMany('case-type'),
	organizations: hasMany('organization'),
	paidPartnerFirm: attr('boolean'),
	firmSettings: belongsTo('firm-setting'),
	medicalProviders: hasMany('medical-provider'),
});
