/** @format */

import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import EmberObject, { computed, observer } from '@ember/object';
import Table from 'ember-light-table';
import { dateDisplay } from 'case-status/helpers/date-display';
import { attorneyDisplay } from 'case-status/helpers/attorney-display';
import { paralegalDisplay } from 'case-status/helpers/paralegal-display';
import { dynamicCaseLabel } from 'case-status/helpers/dynamic-case-label';
import { capitalize } from '@ember/string';
import { A } from '@ember/array';
import { isArray } from 'case-status/constants/data-types';
import { MESSAGE_CATEGORIES, SENTIMENT_MAPPER, URGENCY_MAP } from '../constants/messages';

function actuallyResetTableLocation() {
	const $navbar = $('.navbar');
	const $navbarSecondary = $('.navbar--secondary');
	const $bulkActions = $('.bulk-actions');
	const $secondaryTable = $('.container--table.secondary-2');
	const navbarHeight = $navbar.height();
	const navbarSecondaryHeight = $navbarSecondary.height();

	// update location of bulk actions section and the top of the cases table
	const bulkActionsNewTop = navbarHeight + navbarSecondaryHeight;
	$bulkActions.css('top', bulkActionsNewTop);
	$secondaryTable.css('top', bulkActionsNewTop);

	// update top of cases table to be below bulk actions section
	const secondaryTableNewMarginTop = $bulkActions.height();
	$secondaryTable.css('margin-top', secondaryTableNewMarginTop);

	// update first row of cases table to be below the table's head
	const $casesTable = $('.lt-body-wrap table');
	const $casesTableHead = $('.lt-head table');
	const casesTableNewMarginTop = $casesTableHead.height();
	$casesTable.css('margin-top', casesTableNewMarginTop);
}

export function resetTableLocation(wait_milliseconds = 1000) {
	/*
	 * Reset where the top of the table is (useful on window resizes or when case preferences are selected
	 * causing the preferences to spill over onto a new line).
	 */
	// give the GUI elements time to process before resetting the table location
	setTimeout(actuallyResetTableLocation, wait_milliseconds);
}

export default Component.extend({
	currentUser: service(),
	company: service(),
	permissions: service(),
	ajax: service(),

	columnOptions: computed(
		'permissions.firmSettings.allow_message_translation',
		'permissions.firmSettings.include_case_managers',
		'company.info.isLawFirm',
		'currentUser.user.firm',
		'languages',
		function () {
			const languages = this.languages;
			const currentUserFirm = this.get('currentUser.user.firm');
			// NOTE: the order of the keys determines the order in which they show up in the Column Settings dropdown
			const colOpts = {
				caseId: {
					pos: 0,
					title: `${capitalize(dynamicCaseLabel([this.company]))} ID`,
					label: 'ID', // Case ID
					valuePath: 'readableId',
					draggable: true,
					resizable: true,
					width: '100px',
					cellClassNames: 'light ellipsis',
					cellComponent: 'cell-overflow-hover',
					isStatic: true, // Custom prop to assert if the user can remove it from their columns displayed
					group: 'top-left', // Custom prop to determine where this setting should be oriented
				},
				date: {
					pos: 9,
					label: 'Date',
					title: 'Case Date',
					valuePath: 'date',
					draggable: true,
					resizable: true,
					format: function (value) {
						if (value) {
							return dateDisplay([value, currentUserFirm]);
						} else {
							return 'No Date';
						}
					},
					cellClassNames: 'italic',
					width: '140px',
					group: 'top-left',
				},
				practiceArea: {
					pos: 7,
					label: 'Practice Area',
					valuePath: 'caseType',
					draggable: true,
					resizable: true,
					format: function (value) {
						if (value) {
							return value.get('name');
						} else {
							return 'N/A';
						}
					},
					cellClassNames: 'light ellipsis',
					cellComponent: 'cell-overflow-hover',
					group: 'top-left',
				},
				status: {
					pos: 8,
					label: 'Status',
					valuePath: 'caseStatus',
					cellComponent: 'status-cell',
					draggable: true,
					resizable: true,
					isStatic: true, // Custom prop to assert if the user can remove it from their columns displayed
					group: 'top-left',
				},
				client: {
					pos: 1,
					label: 'Client',
					valuePath: 'clients',
					cellClassNames: 'ellipsis',
					cellComponent: 'cell-cases-client',
					draggable: true,
					resizable: true,
					group: 'bottom-left',
				},
				clientFirstName: {
					pos: 2,
					title: 'Client First Name',
					label: 'First',
					valuePath: 'clients',
					draggable: true,
					resizable: true,
					format: function (value) {
						if (value) {
							if (value.toArray && Array.isArray(value.toArray())) {
								const arr = value.toArray();
								if (arr[0] && arr[0].firstName) {
									return arr[0].firstName;
								} else {
									return '';
								}
							} else return '';
						} else {
							return '';
						}
					},
					cellClassNames: 'light ellipsis',
					cellComponent: 'cell-overflow-hover',
					group: 'bottom-left',
				},
				clientLastName: {
					pos: 3,
					title: 'Client Last Name',
					label: 'Last',
					valuePath: 'lastName',
					sortable: true,
					draggable: true,
					resizable: true,
					format: function (value) {
						if (value) {
							if (value.toArray && Array.isArray(value.toArray())) {
								const arr = value.toArray();
								if (arr[0] && arr[0].lastName) {
									return arr[0].lastName;
								} else {
									return '';
								}
							} else return '';
						} else {
							return '';
						}
					},
					cellClassNames: 'light ellipsis',
					cellComponent: 'cell-overflow-hover',
					group: 'bottom-left',
				},
				targetLanguage: {
					pos: 15,
					label: 'Client Language',
					title: 'Client Language',
					valuePath: 'targetLanguage',
					cellClassNames: 'light ellipsis italic',
					cellComponent: 'cell-overflow-hover',
					draggable: true,
					resizable: true,
					format: function (value) {
						if (value) {
							const lang = languages?.find((lang) => lang.code == value);

							return lang && lang.code && lang.name
								? `(${lang.code}) ${lang.name}`
								: '-';
						} else {
							return '-';
						}
					},
					group: 'bottom-left',
				},
				attorney: {
					pos: 4,
					label: attorneyDisplay([currentUserFirm]),
					valuePath: 'attorneys',
					cellComponent: 'attorney-cell',
					draggable: true,
					resizable: true,
					group: 'bottom-left',
				},
				primary: {
					pos: 5,
					title: `Primary ${attorneyDisplay([currentUserFirm])}`,
					label: 'Primary',
					valuePath: 'primaryAttorney',
					draggable: true,
					resizable: true,
					format: function (value) {
						if (value) {
							return value.get('name');
						} else {
							return 'N/A';
						}
					},
					cellClassNames: 'light ellipsis cy-cases-primary-attorney-cell',
					cellComponent: 'cell-overflow-hover',
					group: 'bottom-left',
				},
				paralegal: {
					pos: 6,
					label: paralegalDisplay([currentUserFirm, 2, true]),
					valuePath: 'paralegals',
					cellComponent: 'paralegal-cell',
					draggable: true,
					resizable: true,
					group: 'bottom-left',
				},
				unread: {
					pos: 12,
					title: 'Unread Messages',
					valuePath: 'unreadMessageCount',
					cellComponent: 'new-messages-cell',
					draggable: true,
					resizable: true,
					width: '70px',
					align: 'center',
					sortable: false,
					isStatic: true,
					group: 'top-right',
				},
				messageUrgency: {
					pos: 16,
					label: 'Urgency',
					title: 'Message Urgency',
					valuePath: 'mostRecentMessageUrgency',
					draggable: true,
					resizable: true,
					width: '102px',
					cellClassNames: 'italic',
					format: function (value) {
						if (value) {
							const label = URGENCY_MAP.get(value);
							return `${value} - ${label}`;
						} else {
							return '';
						}
					},
					group: 'top-right',
				},
				messageCategory: {
					pos: 17,
					label: 'Message Category',
					title: 'Message Category',
					valuePath: 'mostRecentMessageCategory',
					draggable: true,
					resizable: true,
					width: '134px',
					cellClassNames: 'italic',
					format: function (value) {
						if (value) {
							const label = MESSAGE_CATEGORIES[value.toLowerCase()];
							return label || '';
						} else {
							return '';
						}
					},
					group: 'top-right',
				},
				messageSentiment: {
					pos: 18,
					label: 'Message Sentiment',
					title: 'Message Sentiment',
					valuePath: 'mostRecentMessageSentiment',
					draggable: true,
					resizable: true,
					width: '144px',
					cellClassNames: 'italic',
					format: function (value) {
						if (value) {
							return SENTIMENT_MAPPER(value);
						} else {
							return '';
						}
					},
					group: 'top-right',
				},
				nps: {
					pos: 10,
					label: 'NPS',
					valuePath: 'mostRecentNps',
					draggable: true,
					resizable: true,
					format: function (value) {
						if (value !== null && value !== undefined) {
							return value;
						} else {
							return 'N/A';
						}
					},
					group: 'bottom-right',
				},
				updated: {
					pos: 11,
					label: 'Updated',
					title: 'Last Updated',
					valuePath: 'updatedAt',
					draggable: true,
					resizable: true,
					format: function (value) {
						return dateDisplay([value, currentUserFirm]);
					},
					cellClassNames: 'italic',
					width: '130px',
					group: 'bottom-right',
				},
				lastInteraction: {
					pos: 14,
					label: 'Last Interaction',
					title: 'Last Interaction',
					valuePath: 'mostRecentMessageSentAt',
					cellClassNames: 'light ellipsis italic',
					draggable: true,
					resizable: true,
					cellComponent: 'cell-overflow-hover',
					format: function (value) {
						if (value) {
							return dateDisplay([value, currentUserFirm, true]);
						} else {
							return null;
						}
					},
					group: 'bottom-right',
				},
				referralSource: {
					pos: 13,
					label: 'Source',
					title: 'Referral Source',
					valuePath: 'referralSource',
					cellClassNames: 'light ellipsis',
					draggable: true,
					resizable: true,
					cellComponent: 'cell-overflow-hover',
					group: 'bottom-right',
				}
			};

			if (!this.get('company.info.isLawFirm')) {
				delete colOpts.paralegal;
			}

			if (!this.get('permissions.firmSettings.allow_message_translation')) {
				delete colOpts.targetLanguage;
			}

			if (!this.get('permissions.firmSettings.include_case_managers')) {
				delete colOpts.paralegals;
			}

			return colOpts;
		},
	),

	columnOptionsArray: computed('columnOptions.[].{}', function () {
		const optionsArr = [];

		for (let prop in this.columnOptions) {
			optionsArr.push(EmberObject.create(this.columnOptions[prop]));
		}

		return optionsArr;
	}),

	defaultColumns: computed(function () {
		const colOpts = this.columnOptions;
		const columns = [
			colOpts.caseId,
			colOpts.client,
			colOpts.primary,
			// paralegals if isFirm,
			colOpts.date,
			colOpts.status,
			colOpts.updated,
			colOpts.unread,
		];

		if (this.get('company.info.isLawFirm')) {
			columns.splice(4, 0, colOpts.paralegal);
		}

		return this.formatColumns(columns);
	}),

	columns: computed(
		'currentUser.user.preferences.cases_page.cases_table_columns',
		'defaultColumns',
		function () {
			const userColumnPreferences = isArray(
				this.currentUser.get('user.preferences.cases_page.cases_table_columns'),
				true,
			)
				? this.currentUser.get(
						'user.preferences.cases_page.cases_table_columns',
				  )
				: [];
			const columnPreference = this.parseColumnPreferences(
				userColumnPreferences,
			);
			const defaultColumns = this.defaultColumns || [];
			const columns =
				columnPreference && columnPreference.length
					? columnPreference
					: defaultColumns;

			return this.formatColumns(columns);
		},
	),

	model: null,
	table: null,

	async init() {
		this._super(...arguments);

		this.createTable();
	},

	createTable(...callbacks) {
		const cols = this.columns;
		const mod = this.model;
		const table = Table.create({ columns: cols, rows: mod, enableSync: true });
		const sortColumn = table.get('allColumns').findBy('valuePath', 'updatedAt');

		window.onresize = function () {
			resetTableLocation(0);
		};

		if (sortColumn) {
			// If case sortColumn wouldn't exist for some reason, we'd still want to minimize the bug
			// and have it just be that the table isn't sorted on load instead of the table never loads
			sortColumn.set('sorted', true);
			sortColumn.set('ascending', false);
		}

		highlightCaseRows(table.get('rows'));

		this.set('table', table);
		this.set('model', mod);

		// Scroll fixed-position table header when the rest of the table scrolls
		$('#table').on('scroll', function () {
			const scrollOffset =
				document.getElementById('table').scrollLeft * -1 + 'px';
			$('.lt-head table').css('left', scrollOffset);
		});

		// Loop through callbacks passed and pass back an object
		// with the table, cols, mod, and opts to be viewed/used
		for (let i = 0; i < callbacks.length; i++) {
			const callback = callbacks[i];
			if (callback && typeof callback === 'function') {
				callback({ table, cols, mod });
			}
		}

		resetTableLocation();
	},

	modelObserver: observer(
		'model.[]',
		'table',
		'model.@each.onHold',
		'model.@each.activatedDate',
		'table.rows.content.@each.onHold',
		'table.rows.content.@each.activatedDate',
		'table.rows.content.[]',
		function () {
			const table = this.table;
			if (table) {
				const rows = table?.get('rows');
				highlightCaseRows(rows);
			}
		},
	).on('init'),

	willDestroy() {
		this._super(...arguments);
		const columns = this.table.columns;
		const currentUser = this.currentUser.get('user');
		if (!currentUser.get('preferences')) {
			currentUser.set('preferences', {
				cases_page: { cases_table_columns: [] },
			});
		}

		currentUser.set('preferences.cases_page.cases_table_columns', columns);

		currentUser.save();
	},

	formatColumns(columns = []) {
		const freshColumns = columns.filter((option) => {
			return option.label != 'select' && option.label != 'settings';
		});

		// Puts Select column always at front
		if (
			freshColumns[0] &&
			freshColumns[0].label !== 'select' &&
			this.permissions.user.bulk_actions
		) {
			freshColumns.splice(0, 0, {
				label: 'select',
				component: 'cell-select-header',
				width: '60px',
				cellClassNames: 'light',
				cellComponent: 'cell-select',
				sortable: false,
				draggable: false,
				resizable: false,
				align: 'center',
				valuePath: 'id',
			});
		}

		// Puts Settings column always at the end
		if (
			freshColumns[freshColumns.length - 1] &&
			freshColumns[freshColumns.length - 1].label !== 'settings'
		) {
			freshColumns.push({
				label: 'settings',
				component: 'cell-cases-table-settings',
				cellClassNames: 'light',
				sortable: false,
				draggable: false,
				resizable: false,
				align: 'right',
			});
		}

		return freshColumns;
	},

	parseColumnPreferences(userColumnPreferences = {}) {
		const columns = A([]);

		userColumnPreferences.forEach((col, i) => {
			const match = this.columnOptionsArray.find((opt) => {
				return col.pos === opt.pos;
			});

			if (match) {
				match.width = col.width;
				match.index = i;
				columns.push(match);
			}
		});

		return columns.sortBy('index').toArray();
	},

	actions: {
		rowClicked(row, event) {
			// Don't process row click if clicked cell has a checkbox
			if (
				event.target.className.includes('checkbox') ||
				event.target.className.includes('control')
			)
				return;
			if (event.target.children[0]) {
				if (
					event.target.children[0].className &&
					event.target.children[0].className.includes('checkbox')
				)
					return;
				if (event.target.children[0].children[0]) {
					if (
						event.target.children[0].children[0].className &&
						event.target.children[0].children[0].className.includes('checkbox')
					)
						return;
				}
			}
			this.caseClicked(row.content.id);
		},

		onColumnClick(column) {
			if (column.label == 'select') return;

			if (column.sorted) {
				const direction = column.ascending ? '+' : '-';
				let columnName = column.valuePath;

				//Format case status path
				if (columnName === 'caseStatus') {
					columnName = 'case_status.name';
				}

				this.updateSort(columnName, direction);
			}
		},

		onScrolledToBottom() {
			this.fetchMoreCases();
		},

		updateColumns(newColumns = [], ...callbacks) {
			const colPref = {};
			for (let i = 0; i < newColumns.length; i++) {
				const col = newColumns[i];
				colPref[i] = col.pos;
			}

			if (!this.currentUser.get('preferences')) {
				this.currentUser.set('preferences', {
					cases_page: { cases_table_columns: [] },
				});
			}

			this.set(
				'currentUser.user.preferences.cases_page.cases_table_columns',
				colPref,
			);
			const formattedNewColumns = this.formatColumns(newColumns);

			this.table.setColumns(formattedNewColumns);
			this.set('columns', formattedNewColumns);

			//* Update the sort back and forth to trigger the data in the table to refresh,
			//* this helps lower the chugging of having to loop through all the data loaded
			//* to adjust for the new columns
			//* Still needs optimizing or a better solution, but my time was pulled elsewhere. - Aderhold
			this.updateSort('readable_id', '-');
			this.updateSort('readable_id', '+');

			// Loop through callbacks in order
			for (let i = 0; i < callbacks.length; i++) {
				const callback = callbacks[i];

				if (callback && typeof callback === 'function') {
					callback(); //? Should we pass anything to the callbacks? Probably not yet.
				}
			}

			this.currentUser.user.save();
		},
	},
});

function highlightCaseRows(rows) {
	if (rows?.content?.length > 0) {
		return rows.content.map(function (row) {
			const content = row.get('content');
			if (content && content.get('onHold')) {
				return row.set('classNames', ['on-hold']);
			} else if (!content || !content.get('activatedDate')) {
				return row.set('classNames', ['currently-onboarding']);
			} else {
				row.set('classNames', []);
			}
		});
	}
}
